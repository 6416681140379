import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <Container>Careers Page is under construction</Container>;
};

export default Careers;
