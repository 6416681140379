import React from "react";

const IconArrow = () => (
  <svg
    version="1.1"
    id="Layer_1"
    fill="evenodd"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 121.43"
  >
    <g>
      <path d="M112.67,62.75L6.45,115.3l27.99-52.55H112.67L112.67,62.75z M121.62,59L2.78,0.2C1.82-0.27,0.67,0.12,0.2,1.08 C-0.09,1.66-0.05,2.3,0.23,2.83l-0.01,0l30.88,57.98L0.22,118.79l2.56,2.64L121.8,62.55l0-0.01c0.64-0.31,1.08-0.97,1.08-1.72 c0,0,0,0,0,0C122.88,60,122.38,59.28,121.62,59L121.62,59L121.62,59z" />
    </g>
  </svg>
);

export default IconArrow;
